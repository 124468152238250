<script lang="ts" setup>
interface Props {
  rating: number
  max?: number
  withText?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  max: 5,
  withText: false,
})

const roundedRating = Math.round(props.rating)
</script>

<template>
  <div class="inline-flex items-end">
    <UIcon
      v-for="i in props.max"
      :key="i"
      name="i-fas-star"
      :class="{
        'text-yellow-400': i <= roundedRating,
        'text-gray-300': i > roundedRating,
      }"
    />
    <p v-if="withText" class="ml-1 leading-none text-gray-500">
      {{ rating.toFixed(1) }}/{{ props.max.toFixed(1) }}
    </p>
  </div>
</template>
